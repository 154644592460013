.SearchBox {
    position: relative;
    text-align: left;
    border: 1px solid lightgrey;
    border-radius: 2px;
    min-width: 120px;
    font-size: 16px;
    border-radius: 4px;
}

.SearchBox input {
    border: 0px;
    height: 100%;
    font-size: 16px;
    width: 100%;
    max-width: none;
    border-radius: 4px;
}

.ItemList {
    z-index: 10;
    position: absolute;
    background-color: #fff;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    list-style-type: none;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.ItemList li:hover {
    background-color: #f2efe9;
}

.ItemList li {
    padding-left: 10px;
    border-top: 1px solid #EEE;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #fff;
    cursor: pointer;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
}
