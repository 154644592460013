@font-face {
    font-family: BosisOresund;
    src: url(./fonts/BOSIOB__.woff);
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: BosisOresund;
    src: url(./fonts/BOSIORG_.woff);
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: BosisOresundLight;
    src: url(./fonts/BOSIS_L.TTF);
    font-weight: 400;
    font-style: normal
}