@import url('https://fonts.googleapis.com/css?family=Bitter');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
html {
    /* font-size: 12px; */
    font-size: 100%;
}

.btn.btn-warning:disabled {
    width: 70%;
    height: 50px;
    font-size: 1.25rem;
    background-color: #f7de9c;
}

.btn.btn-warning {
    width: 70%;
    height: 50px;
    font-size: 1.25rem;
    background-color: #eaad07;
}

.bold {
    font-weight: 600;
}

.form-check-label {
    margin-Bottom: 10px;
}

table,
td,
th {
    border-collapse: collapse;
}

.Trafficbox {
    width: 300px;
}

.InlineBlock {
    display: inline-block;
}

.DarkTextArea {
    padding: 10px;
    background-color: black;
    color: white;
}

.Footer {
    font-weight: 600;
    padding: 10px;
    font-family: "BosisOresund";
    font-size: 30px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-left: 22px;
    padding-right: 20px;
    background-color: white;
}

.Header {
    background-repeat: no-repeat;
    background-size: contain;
    white-space: nowrap;
    height: 90px;
    position: relative;
}

.Header .Title {
    margin-left: 40px;
    font-family: "BosisOresund";
    font-size: 2.8vw;
    font-weight: bold;
    color: #FFFFFF;
    vertical-align: middle;
    display: inline-block;
}

.HeaderBackground {}

.App {}

.JourneyList {
    padding: 10px;
}

.JourneyListHeader {
    font-size: 12pt;
    padding-left: 6px;
    padding-right: 6px;
    color: #5f5f5f;
}

.JourneyListHeader th {
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    font-weight: 400;
    text-transform: uppercase;
}

.JourneyItem {
    font-family: "BosisOresund";
    font-size: 24pt;
    /*font-size: 1.6vw;*/
    white-space: nowrap;
}

.JourneyItem td {
    padding: 20px;
    padding-left: 12px;
    padding-right: 12px;
}

.JourneyItem td.UpdatedTime {
    padding-left: 0px;
    padding-right: 10px;
    color: #e00201;
    font-weight: bold;
    padding-top: 25px;
}

.JourneyItem td.Direction {
    /*max-width: 220px;
  min-width: 220px;*/
    width: 100%;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.JourneyItem td.Platform {
    width: 5%;
}

.JourneyItem td.TimeCountDown {
    width: 7%;
    text-align: right;
    font-weight: bold;
    padding-top: 25px;
}

.JourneyCanceledMessage {
    font-size: 15pt;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 6px;
    vertical-align: middle;
    font-weight: bold;
}

.CanceledBlock {
    display: inline-block;
    margin-right: 8px;
    margin-left: 10px;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 4px;
    padding: 6px 20px 6px 20px;
    color: #e43434;
    border: 3px solid #e43434;
}

.CanceledText {
    color: #e43434;
    padding-bottom: 6px;
    margin-left: 2px;
}

.JourneyMessage {
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -6px;
    margin-bottom: 12px;
    border-radius: 5px;
    font-size: 15pt;
    font-weight: bold;
    line-height: 1.15;
    overflow-x: hidden;
}

td.JourneyItemIcon {
    padding: 0px;
}

td.JourneyItemIcon.JourneyItemBusIcon {}

.changed {
    text-decoration: line-through;
}

.JourneyListFullscreenMessage {
    display: table;
    width: 100%;
    vertical-align: middle;
}

.JourneyListFullscreenMessage .JourneyListFullscreenMessageBox {
    display: table-cell;
    vertical-align: middle;
}

.JourneyListFullscreenMessage .JourneyListFullscreenMessageHeader {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}

.JourneyListFullscreenMessage .JourneyListFullscreenMessageBody {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.iconContainer {}

.svgConnTypeTitleIcon {
    margin-left: 12px;
    margin-bottom: 12px;
    margin-right: -7px;
    /*margin-top: 20px;*/
    fill: #FFFFFF;
    vertical-align: middle;
    display: inline-block;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.svgPlateTextPagatag {
    padding-top: 3px;
    height: 37px;
    width: 95px;
    display: inline-block;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    background-color: black;
}

.svgPlateTextOresundstag {
    padding-top: 3px;
    height: 37px;
    width: 104px;
    display: inline-block;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    background-color: black;
}

.svgPlateTextKrosatag {
    text-align: center;
    padding-top: 3px;
    height: 37px;
    width: 104px;
    display: inline-block;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    background-color: black;
}

.svgPlateText {
    padding-top: 3px;
    height: 37px;
    width: 95px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    background-color: black;
}

.svgTrainIcons {
    position: relative;
    margin-left: 4px;
}

.oresundstag {
    fill: #797979;
}

.krosatag {
    fill: #797979;
}

.pagatag {
    fill: #6660c4;
}

.svgPlateTextPagatag:after {
    content: "PÅGATÅG";
    font-size: 13.5pt;
    vertical-align: middle;
    margin-left: 5px;
}

.svgPlateTextOresundstag:after {
    content: "ÖRESUNDSTÅG";
    font-size: 11.5pt;
    vertical-align: middle;
    margin-left: 2px;
}

.svgPlateTextKrosatag:after {
    content: "KRÖSATÅG";
    font-size: 13.5pt;
    vertical-align: middle;
    margin-left: 2px;
}

.roundedPlate {
    position: relative;
    margin-left: 6px;
    display: block;
    /*display: flex;
    align-items: flex-start;
    display: -webkit-flex;
    -webkit-align-items: flex-start;*/
}

.plateTitle {
    position: absolute;
    width: 54%;
    margin-left: 16px;
    margin-top: 8px;
    word-break: break-all;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    font-family: 'BosisOresund';
    font-size: 24pt;
    font-weight: bold;
    color: white;
}

.connView .connRow .roundedPlate {
    margin-left: 34px;
}

.connView .connRow .plateTitle {
    font-size: 49pt;
}

.connView .connRow .svgPlate,
.connView .connRow .roundedPlate {
    width: 177px;
    height: 85px;
    /*transform: scale(1.1);*/
}

.connView .connRow .svgPlate path {
    /*fill: #000000;*/
    /*transform: scale(1.1);*/
}

/* Title in connection list row */

.connView .connRow .connTitle {
    margin-left: 60px;
    margin-right: 40px;
    margin-top: -2px;
    width: 507px;
    /* letter-spacing: -2px; */
}

/* Title in connection list row */

.connView .connRow .connTitleBus {
    margin-left: 60px;
    width: 1162px;
}

/* Stop point in connection list row */

.connView .connRow .connStop {
    margin-left: 28px;
    margin-top: -2px;
    width: 90px;
    word-break: break-all;
    text-align: center;
}

/* Departure time in connection list row (train) */

.connView .connRow .connDep {
    margin-left: 100px;
    margin-top: -2px;
    width: 220px;
    word-break: break-all;
    text-align: center;
}

/* New time in connection list row (train)*/

.connView .connRow .connNewTime {
    margin-left: 20px;
    margin-right: 95px;
    margin-top: -2px;
    width: 220px;
    word-break: break-all;
    text-align: center;
}

/* Canceled plaque (train) */

.connView .connRow .canceledPlaque {
    margin-left: 40px;
    /*margin-right: 185px;*/
    height: 66px;
    border-radius: 8px;
    width: 293px;
    background-color: #d72d2d;
    color: #ffffff;
    font-weight: bold;
    font-size: 31pt;
    margin-top: 1px;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -webkit-align-content: center;
}

/* Num min in connection list row */

.connView .connRow .connMin {
    margin-left: 28px;
    margin-right: 30px;
    margin-top: 10px;
    font-weight: bold;
    width: 345px;
    text-align: right;
    word-break: break-all;
}

.connView .svgConnTypeTitleIcon {
    margin-left: 56px;
    margin-right: -7px;
}

.connView .svgConnTypeTitleIcon path,
.connView .svgConnTypeTitleIcon polygon {
    fill: #FFFFFF;
}

.connView .svgConnTypeTitleIcon .SmallTrainJourneyItem path,
.connView .svgConnTypeTitleIcon polygon {
    fill: #6E6065;
}

.iconContainer {
    /*transform: scale(1.25);*/
}

.iconContainer img {
    margin-left: 54px;
    margin-top: 12px;
}

.skanetrafiken-logo--bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 2%;
}

.error-message-wrapper {
    padding: 15% 40px;
}

@media screen and (min-width: 1160px){
    .error-message-wrapper {
        padding: 15% 250px;
    }
}

.error-message__text {
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    font-size: 3vw;
    font-weight: 700;
}