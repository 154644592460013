*,
*::before,
*::after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.input-group-text {
    box-sizing: content-box;
}

.form-control,
.SearchBox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.SearchBox input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}