@import url('https://fonts.googleapis.com/css?family=Bitter');
html,
body,
#root {
    height: 100%;
}

.configurator-header {
    font-weight: 700;
    font-family: 'Bitter', serif;
    padding-top: 8px;
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 1.4rem;
}

.skanetrafiken-logo {
    position: absolute;
    top: 10px;
    left: 8px;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.bold {
    font-weight: 600 !important;
}

.configurator-rounded-plate {
    margin: 30px;
    max-width: 500px;
    background-color: #ffffff;
    z-index: 0;
    -webkit-box-shadow: 0px 0px 10px 0px #00000038;
    box-shadow: 0px 0px 10px 0px #00000038;
    border-radius: 5px;
    font-family: 'Source Sans Pro', sans-serif;
}

.configurator-header-plate {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    background-color: #e9ecef;
    border-radius: 5px 5px 0px 0px;
}

.configurator-checkbox {
    width: 20px;
    height: 20px;
}

.configurator-checkbox-label {
    margin-top: 2px;
    margin-left: 8px;
}

.configuration-container {
    padding-top: 60px;
    padding-bottom: 120px;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-align: center;
}

.input-group {
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
}

.invalid-input {
    border: 1px solid red;
    border-radius: 4px;
}

.error-message {
    padding: 4px 8px;
    color: red;
}

@media (min-width: 0px) {
    .configurator-content {
        padding: 10px 20px;
    }
}

@media (min-width: 540px) {
    .configuration-container {
        padding-top: 120px;
    }
    .configurator-content {
        padding: 45px 70px;
    }
    .configurator-rounded-plate {
        width: 500px;
    }
    .skanetrafiken-logo {
        left: 110px;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    .configurator-header {
        font-size: 1.6rem;
    }
}